'use strict';
import $ from 'jquery';
export default class Modal {
  constructor() {
    if ($('.md-modal').length > 0) {
      $('<div />').addClass('modal-bg').appendTo('body');
    }
    this._onClick();
  }

  _onClick() {
    $('.open-modal').on('click', (e) => {
      e.preventDefault();
      this._showModalBG();
      let $target = $(`#${$(e.currentTarget).data('target')}`);
      $target.addClass('md-show');
    });

    $('.modal-close').on('click', (e) => {
      e.preventDefault();
      if ($('.md-modal.md-show').find('.video-container').length > 0) {
        this._stopModalVideo($('.md-modal.md-show').find('.video-container iframe'));
      }
      $(e.currentTarget).parent().removeClass('md-show');

      this._hideModalBG();
    });
  }

  _showModalBG() {
    $('.modal-bg').addClass('is-visible');
  }

  _hideModalBG() {
    $('.modal-bg').removeClass('is-visible');
  }

  _stopModalVideo($elem) {
    $elem.attr('src', $elem.attr('src'));
  }

}
