'use strict';

import $ from 'jquery';
export default class HomeContent {

  constructor() {
    this.$hc = $('.home-content');
    this.$parent = $('.content-content');
    if (this.$hc.length > 0) {
       this.setHomeBlockSizes();
    }

    this.onResize();
  }

  setHomeBlockSizes() {
    if ($(window).width() > 720) {
      this.$hc.height(this.$parent.height() - $('.main-footer').height() - 10)
    } else {
      this.$hc.css({'height' : ''});
    }
  }

  animateHomeBlocks() {

  }

  onResize() {
    $(window).on('resize', () => this.setHomeBlockSizes());
  }
}
