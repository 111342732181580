// Main javascript entry point
// Should handle bootstrapping/starting application

'use strict';

import $ from 'jquery';
// global.$ = global.jQuery = require('jquery');

import HomeContent from '../_modules/home-content/home-content';
import Modal from '../_modules/modal/modal';
import SidebarNews from '../_modules/sidebar-news/sidebar-news';
import NewsueberblickContent from '../_modules/newsueberblick-content/newsueberblick-content';
import Login from '../_modules/login/login';
import Register from '../_modules/register/register'
import TopQuestions from '../_modules/top-questions/top-questions';

import Addons from './addons';
import Animations from './animations';
import SVGLoader from './svgloader';

$(() => {

  /* Loader */
  window.loaderOut = new SVGLoader(document.getElementById( 'loaderOut' ));
  window.loaderIn = new SVGLoader(document.getElementById( 'loaderIn' ));

  $('.follow').on('click', (e) => {
    e.preventDefault();
    let $link = $(e.currentTarget);
    let url = $link.attr('href');
    window.loaderIn.start( () => {
        window.location = url;
      }
    );
  });

  new Addons();
  new HomeContent();
  new Animations();
  new SidebarNews();
  new NewsueberblickContent();
  new Modal();
  new Login();
  new Register();
  new TopQuestions();

});
