'use strict';
import $ from 'jquery';
require('malihu-custom-scrollbar-plugin')($);

export default class Addons {

  constructor() {
    this.CustomScrollBar();
  }

  CustomScrollBar() {
    let $sc = $('.scroll-container');

    $sc.mCustomScrollbar({
      scrollInertia: 0,
      scrollbarPosition: "inside",
      autoHideScrollbar: true,
      updateOnContentResize: true
    });
  }

  static RefreshScrollBars() {
    let $sc = $('.scroll-container');
    $sc.mCustomScrollbar('update');
  }



}