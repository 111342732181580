'use strict';

import $ from 'jquery';
import owlcarousel from 'owl.carousel';
import matchHeight from 'jquery-match-height';
import News from '../../_scripts/news';

export default class NewsueberblickContent {

  constructor() {
    let owl;
    if ($('.newsueberblick--articles').length > 0) {
      owl = $('.newsueberblick--articles').owlCarousel({
        items: 4
      });

      owl.on('changed.owl.carousel', (event) => {
        let items     = event.item.count;     // Number of items
        let item      = event.item.index;     // Position of the current item
        let currentLast = item + 4;
        if (item > 0) {
          this._enableButton('left');
        } else {
          this._disableButton('left');
        }

        if (currentLast < items) {
          this._enableButton('right');
        } else {
          this._disableButton('right');
        }


      });

      $('.news-navi-button--left').click((e) => {
        if (!$(e.currentTarget).hasClass('disabled')) {
          owl.trigger('prev.owl.carousel');
        }

      });

      $('.news-navi-button--right').click((e) => {
        if (!$(e.currentTarget).hasClass('disabled')) {
          owl.trigger('next.owl.carousel');
        }
      });

      this._setHeight();

      this._onArticleClick();
      this._onCloseClick();
    }
  }

  _onArticleClick() {
    $('.news-overview--article .read-more').on('click', (e) => {
      e.preventDefault();
      let url = $(e.currentTarget).attr('href');
      if ($('.sidebar-news--article.is-active').length > 0) {
        $('.sidebar-news--article.is-active').removeClass('is-active');
        News.openArticleInsteadOfCurrent(url);
      } else {
        News.openNewArticle(url);
      }
    });
  }

  _onCloseClick() {

  }

  _disableButton(dir = 'left') {
    if (dir === 'left') {
      if (!$('.news-navi-button--left').hasClass('disabled')) {
        $('.news-navi-button--left').addClass('disabled');
      }

    } else {
      if (!$('.news-navi-button--right').hasClass('disabled')) {
        $('.news-navi-button--right').addClass('disabled');
      }
    }
  }

  _enableButton(dir = 'left') {
    if (dir === 'left') {
      if ($('.news-navi-button--left').hasClass('disabled')) {
        $('.news-navi-button--left').removeClass('disabled');
      }
    } else {
      if ($('.news-navi-button--right').hasClass('disabled')) {
        $('.news-navi-button--right').removeClass('disabled');
      }
    }
  }

  _setHeight() {
    $('.news-overview--article').matchHeight();
  }

}
