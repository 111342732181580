'use strict';
import $ from 'jquery';
export default class TopQuestions {
  constructor() {
    if ($('.top-question-sidebar').length > 0) {
      this._onClick();
    }
  }

  _onClick() {
    $('.question-article').on('click', (e) => {
      let $current = $(e.currentTarget);
      $current.toggleClass('is-opened');
      if ($current.hasClass('is-opened')) {
        let h = $current.find('.answer-container').height();
        $current.find('.answer').css({'max-height' : h});
      } else {
        $current.find('.answer').css({'max-height' : 0});
      }
    });
  }
}
