'use strict';

import Login from '../login/login'
import inputmask from 'jquery.inputmask';
import Parsley from 'parsleyjs';
import $ from 'jquery';

export default class Register {
  constructor() {
    if ($('.register-form').length > 0) {
      this._masked();
      this._formControl();
      this._backToLogin();
      this._setFormSectionContainerHeight();
      this._formSectionControl();
      this._registerValidation();
    }

  }

  _masked() {
    $('#birthday').inputmask("99/99/9999");
  }

  _registerValidation() {
    let $form = $('.register-form');
    var okForSection1 = false;
    var okForSection2 = false;
    $form.parsley().on('field:validate', function(formInstance) {
      okForSection1 = $form.parsley().isValid({group: 'block1', force: true});
      if (okForSection1) {
        $('input.nextSection').removeClass('inactive');
        $('.register--section-nav').removeClass('disabled');
      } else {
        $('input.nextSection').addClass('inactive');
        $('.register--section-nav').addClass('disabled');
      }

      okForSection2 = $form.parsley().isValid({group: 'block2', force: true});
      if (okForSection2) {
        $('input.submit').removeClass('inactive');
      } else {
        $('input.submit').addClass('inactive');
      }
    });
  }

  _backToLogin() {
    $('.backToLogin').on('click', (e) => {
      e.preventDefault();
      this._hideRegisterPage();
    });
  }

  _setFormSectionContainerHeight() {
    let $fsc = $('.form-section-container');
    let h = $fsc.find('section.active').height();
    $fsc.css({'height' : h});
  }

  _formSectionControl() {
    //let $fsc = $('.form-section-container');
    $('input.nextSection').on('click', (e) => {
      e.preventDefault();
      if (!$(e.currentTarget).hasClass('inactive')) {
        this._toggleSection();
      }
    });

    $('.section-bullet li, .register--section-nav a').on('click', (e) => {
      e.preventDefault();
      let $elem = $(e.currentTarget);
      if (!$elem.hasClass('active') && !$elem.hasClass('inactive')) {
        this._toggleSection();
      }
    });
  }

  _toggleSection() {
    let $fsc = $('.form-section-container');
    let $toBeActive = $fsc.find('section:not(.active)');
    let $currentActive = $fsc.find('section.active');
    let index = $toBeActive.index();
    let $bulletContainer = $('.section-bullet');
    $fsc.animate({'height' : $toBeActive.height()}, 300);
    $currentActive.removeClass('active');
    $toBeActive.addClass('active');
    setTimeout(function () {
      $toBeActive.find('input').first().focus();
    }, 300);

    $toBeActive.find('.input-wrap input').attr('tabindex', 1);
    $currentActive.find('.input-wrap input').attr('tabindex', -1);

    if (index === 0) {
      $('.prev-section').addClass('inactive');
      $('.next-section').removeClass('inactive');
      $bulletContainer.find('li:first-child').addClass('active');
      $bulletContainer.find('li:last-child').removeClass('active');
      $('input.submit').addClass('is-hidden');
      $('input.nextSection').removeClass('is-hidden');
    } else {
      $('.next-section').addClass('inactive');
      $('.prev-section').removeClass('inactive');
      $bulletContainer.find('li:first-child').removeClass('active');
      $bulletContainer.find('li:last-child').addClass('active');
      $('input.submit').removeClass('is-hidden');
      $('input.nextSection').addClass('is-hidden');
    }
  }

  _formControl() {
    let $form = $('.register-form');
    if (!$form.hasClass('has-control')) {
      $form.addClass('has-control');
      let $first = $form.find('.input-wrap').first();
      $first.addClass('show-always');
      $first.find('input').focus();

      $form.find('input').on('focus', (e) => {
        let $elem = $(e.currentTarget);
        $elem.parent().addClass('show');
      });

      $form.find('input').on('blur', (e) => {
        let $elem = $(e.currentTarget);
        if ($elem.val().length === 0) {
          $elem.parent().removeClass('show');
        }
      });
    }
  }

  _hideRegisterPage() {
    $('.register-wrap').removeClass('start-animation');
    setTimeout(() => {
      $('.register-wrap').removeClass('is-visible');
    }, 1000);
    setTimeout(() => Login.showLoginPage(), 1650);
  }

  static showRegisterPage() {
    $('.register-wrap').addClass('is-visible');
    setTimeout(() => {
      $('.register-wrap').addClass('start-animation');
    }, 650);
    //this._formControl();
  }
}
