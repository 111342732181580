'use strict';
import $ from 'jquery';
import News from '../../_scripts/news';
require('malihu-custom-scrollbar-plugin')($);
var imagesLoaded = require('imagesloaded');


export default class SidebarNews {
  constructor() {
    this._onArticleClick();
    this._onCloseClick();
    this._articleNavigation();
  }

  _onArticleClick() {
    $('.sidebar-news--article .read-more').on('click', (e) => {
      e.preventDefault();
      let url = $(e.currentTarget).attr('href');
      let $parents = $(e.currentTarget).parents('.sidebar-news--article');
      if (!$parents.hasClass('is-active')) {
        if ($('.sidebar-news--article.is-active').length > 0) {
          $('.sidebar-news--article.is-active').removeClass('is-active');
          $parents.addClass('is-active');
          News.openArticleInsteadOfCurrent(url);
        } else {
          $parents.addClass('is-active');
          News.openNewArticle(url);
        }
      }
    });
  }

  _onCloseClick() {
    $('.close-article').on('click', (e) => {
      e.preventDefault();
      $('.ajax-article--wrap').removeClass('is-opened start-animation');
      setTimeout(() => {
        $('.ajax-article--content').empty();
      }, 1000);
      $('.sidebar-news--article.is-active').removeClass('is-active');
    });
  }


  _articleNavigation() {

  }
}
