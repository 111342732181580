'use strict';
import $ from 'jquery';
import Register from '../register/register';

export default class Login {
  constructor() {
    this._onRegisterButtonClick();
  }

  _onRegisterButtonClick() {
    $('#register-link').on('click', (e) => {
      e.preventDefault();
      this._hideLoginPage();
    });
  }

  _hideLoginPage() {
    $('.login--content-wrap').addClass('is-hidden');
    setTimeout(() => Register.showRegisterPage(), 650);
  }

  static showLoginPage() {
    $('.login--content-wrap').removeClass('is-hidden');
  }
}
