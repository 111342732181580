'use strict';
import $ from 'jquery';
require('malihu-custom-scrollbar-plugin')($);

// TEST:
var _article = require('../_data/article.json'); // test html is in _article.content
// End TEST

export default class News {
    constructor() {

    }

    static openArticleInsteadOfCurrent(url) {
        News.hideAjaxContent();
        setTimeout(() => {
            $('.ajax-article--wrap').removeClass('start-animation');
            News.loadArticleByURL(url, false);
        }, 501);
    }

    static openAjaxArticleContainer() {
        $('.ajax-article--wrap').addClass('is-opened');
    }

    static hideAjaxContent() {
        $('.ajax-article--content').addClass('is-hidden');
    }

    static showAjaxContent() {
        $('.ajax-article--content').removeClass('is-hidden');
    }

    static openNewArticle(url) {
        News.loadArticleByURL(url);
    }

    static loadArticleByURL(url, open = true) {
        let delay = 0;
        // TEST
        let result = _article.content;
        // End TEST
        // TODO: Make ajax call to article
        if (open) {
            News.openAjaxArticleContainer();
            delay = 1001;
        }

        setTimeout(() => {
            News.setAjaxContent(result, open);
        }, delay);
    }

    static setAjaxContent(content, open) {
        $('.ajax-article--content').html(content);
        if (!open) {
            News.showAjaxContent();
        }
        $('.ajax-article--wrap').imagesLoaded(function () {
            $('.ajax-article--wrap').addClass('start-animation');
        });
        News.setCustomScrollbarOnAjaxContent();
    }

    static setCustomScrollbarOnAjaxContent() {
        $('.ajax-article--wrap').mCustomScrollbar({
            setTop: Math.abs($('.content-content.scroll-container').find( '.mCSB_container' ).position().top) + 'px',
            scrollInertia: 0,
            scrollbarPosition: "inside",
            autoHideScrollbar: true,
            updateOnContentResize: true
        });
       console.log(Math.abs($('.content-content.scroll-container').find( '.mCSB_container' ).position().top));
    }
}