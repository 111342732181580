/**
 * Created by zoltanseer on 11.05.17.
 */
'use strict';
import $ from 'jquery';
import Snap from 'snapsvg';
import classie from 'desandro-classie';

export default class SVGLoader {
  constructor(el) {
    this.el = el;
    this.options = {
      speedIn : 500,
      easingIn : mina.linear
    };
    this._init();
  }

  _init() {
    const s = Snap( this.el.querySelector( 'svg' ) );
    this.path = s.select( 'path' );
    this.initialPath = this.path.attr('d');

    const openingStepsStr = this.el.getAttribute( 'data-opening' );
    this.openingSteps = openingStepsStr ? openingStepsStr.split(';') : '';
    this.openingStepsTotal = openingStepsStr ? this.openingSteps.length : 0;
    if( this.openingStepsTotal === 0 ) return;

    // if data-closing is not defined then the path will animate to its original shape
    const closingStepsStr = this.el.getAttribute( 'data-closing' ) ? this.el.getAttribute( 'data-closing' ) : this.initialPath;
    this.closingSteps = closingStepsStr ? closingStepsStr.split(';') : '';
    this.closingStepsTotal = closingStepsStr ? this.closingSteps.length : 0;

    this.isAnimating = false;

    if( !this.options.speedOut ) {
      this.options.speedOut = this.options.speedIn;
    }
    if( !this.options.easingOut ) {
      this.options.easingOut = this.options.easingIn;
    }
  }

  start(callback) {
    if( this.isAnimating ) return false;
    this.isAnimating = true;
    // animate svg
    const self = this;
    let out = $(this.el).attr('id') === 'loaderOut';

    if (out) {
      classie.removeClass( this.el, 'pageload-loading' );
    }

    const onEndAnimation = () => {
      if (out) {
        classie.removeClass( self.el, 'show' );
        self.isAnimating = false;
      } else {
        classie.addClass( self.el, 'pageload-loading' );
      }
      if (callback) {
        callback();
      }
    };

    classie.add( this.el, 'show' );
    this._animateSVG( 'in', onEndAnimation );
  }

/*  hide(callback) {
    const self = this;

    this._animateSVG( 'out', () => {
      // reset path
      self.path.attr( 'd', self.initialPath );
      classie.removeClass( self.el, 'show' );
      self.isAnimating = false;
      if (callback) {
        callback();
      }
    } );
  }*/


  _animateSVG(dir, callback) {
    const self = this;
    const pos = 0;
    const steps = dir === 'out' ? this.closingSteps : this.openingSteps;
    const stepsTotal = dir === 'out' ? this.closingStepsTotal : this.openingStepsTotal;
    const speed = dir === 'out' ? self.options.speedOut : self.options.speedIn;
    const easing = dir === 'out' ? self.options.easingOut : self.options.easingIn;

    const nextStep = pos => {
      if( pos > stepsTotal - 1 ) {
        if( callback && typeof callback == 'function' ) {
          callback();
        }
        return;
      }
      self.path.animate( { 'path' : steps[pos] }, speed, easing, () => { nextStep(pos); } );
      pos++;
    };

    nextStep(pos);
  }
}