'use strict';
import $ from 'jquery';
import Snap from 'snapsvg';
import classie from 'desandro-classie';
var imagesLoaded = require('imagesloaded');
imagesLoaded.makeJQueryPlugin( $ );

import Addons from './addons';
import SVGLoader from './svgloader';


export default class Animations {

    constructor() {
        if ($('body').hasClass('home') || $('body').hasClass('login')) {
          $('.content-wrap').imagesLoaded().always(window.loaderOut.start(Animations.startAnimations));
        } else {
          $('.content-wrap').imagesLoaded(function () {
              // Animations.hideSimpleLoader();
              // setTimeout(window.loader.hide(), 1000 );
              window.loaderOut.start();
          });
        }
    }

    static startAnimations() {
        let $last;
        let max = 0;
        $('[data-anim-delay]').each(function () {
            let $elem = $(this);
            let d = $elem.data('anim-delay');
            $elem.css({'transition-delay' : d + 'ms'}).addClass('start-animation');
            $elem.on('transitionend', () => $elem.css({'transition-delay' : ''}));
            if (max < d) {
                max = d;
                $last = $elem;
            }
        });

        $last.on('transitionend', () => {
            Animations.endAnimationCallback();
        })

    }

    static endAnimationCallback() {
      Addons.RefreshScrollBars();
    }

    static showSimpleLoader() {
      $('.loader').removeClass('is-hidden');
    }


}